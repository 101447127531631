import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux'
import store from './store';
import { SnackbarProvider } from 'notistack';
import { Auth0Provider } from '@auth0/auth0-react';


const root = ReactDOM.createRoot(document.getElementById('root'));


const getOrgId = () =>
  {
    return localStorage.getItem( 'orgId' );
  };


root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTHZERO_DOMAIN}
      clientId={process.env.REACT_APP_AUTHZERO_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: process.env.REACT_APP_AUTHZERO_AUDIENCE
      }}
      
    >
      <Provider store={store}>
        <SnackbarProvider autoHideDuration={5000}>
          <App />
        </SnackbarProvider>
      </Provider>
    </Auth0Provider>
  </React.StrictMode>
);


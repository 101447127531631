import './App.css';
import Dashboard from './Dashboard';
import LoginButton from './login';
import * as React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import ArticleIcon from '@mui/icons-material/Article';
import TopicIcon from '@mui/icons-material/Topic';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import ProjectPane from './components/ProjectPane';
import Templates from './Templates';
import Settings from './Settings';
import { ViewContext } from './ViewContext';

import '@fontsource-variable/open-sans';
import '@fontsource-variable/inter';

import ArtifactDetail from './components/ArtifactDetail';
import LogoutButton from './logout';
import { UserTile } from './components/UserTile';
const drawerWidth = 240;
const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

function App() {
  const { user, isAuthenticated, isLoading, loginWithRedirect, getOrgId } = useAuth0();
  const [activeProjectId, setActiveProjectId] = React.useState(null);
  const [activeArtifactId, setActiveArtifactId] = React.useState(null);
  const [activeContentType, setActiveContentType] = React.useState(null);

  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  React.useEffect(() => {
    console.log('User is authenticated:', isAuthenticated);
    console.log('User:', user);
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [isLoading, isAuthenticated]);

  const THEME = createTheme({
    typography: {
      fontFamily: `"Inter Variable", sans-serif`,
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
    },

    components: {
      MuiToolbar: {
        styleOverrides: {
          dense: {
            height: 48,
            minHeight: 48,
          },
        },
      },
    },
  });

  const shouldHideDrawer = () => {
    return activeArtifactId !== null;
  };

  const shouldOpenDrawer = () => {
    return true;
  };

  return (
    <div className="App">
      <ThemeProvider theme={THEME}>
        <CssBaseline />
        <ViewContext.Provider
          value={{
            activeProjectId,
            setActiveProjectId,
            activeArtifactId,
            setActiveArtifactId,
            activeContentType,
            setActiveContentType,
          }}
        >
          <Box sx={{ display: 'flex' }}>
            <Drawer variant="permanent" hidden={shouldHideDrawer()} open={shouldOpenDrawer()}>
              <Toolbar
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  px: [1],
                }}
              >
                <Container
                  onClick={() => {
                    setSelectedIndex(1);
                    setActiveProjectId(null);
                  }}
                >
                  <img src="logo.png" style={{ cursor: 'pointer' }} alt="logo" />
                </Container>
                {/* 
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
            */}
              </Toolbar>
              <Divider />
              <Stack
                direction="column"
                spacing={2}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1,
                }}
              >
                <List component="nav">
                  <ListItemButton selected={selectedIndex === 1} onClick={(event) => handleListItemClick(event, 1)}>
                    <ListItemIcon>
                      <ArticleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Projects" />
                  </ListItemButton>
                  <ListItemButton selected={selectedIndex === 2} onClick={(event) => handleListItemClick(event, 2)}>
                    <ListItemIcon>
                      <TopicIcon />
                    </ListItemIcon>
                    <ListItemText primary="Templates" />
                  </ListItemButton>
                  <ListItemButton selected={selectedIndex === 3} onClick={(event) => handleListItemClick(event, 3)}>
                    <ListItemIcon>
                      <ManageAccountsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Settings" />
                  </ListItemButton>
                </List>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                  }}
                />
                <UserTile />
              </Stack>
            </Drawer>
            <Box
              component="main"
              sx={{
                display: 'flex',
                flexGrow: 1,
                height: '100vh',
                flexDirection: 'column',
              }}
            >
              {isAuthenticated && !activeArtifactId && selectedIndex === 1 && <ProjectPane />}
              {isAuthenticated && !activeArtifactId && selectedIndex === 2 && <Templates />}
              {isAuthenticated && !activeArtifactId && selectedIndex === 3 && <Settings />}
              {isAuthenticated && activeArtifactId && <ArtifactDetail artifactId={activeArtifactId} />}
            </Box>
          </Box>
        </ViewContext.Provider>
      </ThemeProvider>
    </div>
  );
}

export default App;

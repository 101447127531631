import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Tab,
  Tabs,
  TextField,
} from '@mui/material';
import { useEffect, useState, useContext } from 'react';
import ArtifactList from './ArtifactList';
import { ViewContext } from '../ViewContext';
import { add, formatDistance } from 'date-fns';
import AssetUploadDialog from './AssetUploadDialog';
import { Article, Delete } from '@mui/icons-material';
import axios from 'axios';
import ProjectSettings from './ProjectSettings';
import { useAuth0 } from '@auth0/auth0-react';
import { enqueueSnackbar } from 'notistack';
import { isAdmin } from '../auth/utils';

function AssetListTable({ project, onAssetsChanged, enqueuedAssets }) {
  const { user, getAccessTokenSilently } = useAuth0();

  const handleDeleteAsset = async (assetId) => {
    // Delete asset from the project
    if (window.confirm('Are you sure you want to delete this asset?')) {
      const url = `${process.env.REACT_APP_API_URL}/projects/${project._id}/assets/${assetId}`;
      const accessToken = await getAccessTokenSilently();
      const headers = { 'content-type': 'application/json', Authorization: `Bearer ${accessToken}` };

      await axios
        .delete(url, { headers: headers })
        .then((response) => {
          console.log('Asset deleted:', response.data);
          onAssetsChanged();
        })
        .catch((error) => {
          if (error.response.status === 403) {
            enqueueSnackbar('You do not have permission to delete this asset.', { variant: 'warning' });
          } else {
            enqueueSnackbar('Error deleting asset', { variant: 'error' });
            console.error('Error deleting asset:', error);
          }
        });
    }
  };

  if (project && project.assets && project.assets.length > 0) {
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Created</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {project.assets.map((row) => (
              <TableRow key={row._id}>
                <TableCell>{row.filename}</TableCell>
                <TableCell>
                  {formatDistance(row.created_at, add(new Date(), { minutes: new Date().getTimezoneOffset() }), {
                    addSuffix: true,
                  })}
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => handleDeleteAsset(row._id)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            {enqueuedAssets.map((row, index) => (
              <TableRow key={row._id}>
                <TableCell>{row.filename}</TableCell>
                <TableCell>
                  <Typography fontStyle={'italic'}>Uploading...</Typography>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  } else {
    return (
      <Paper sx={{ minHeight: 100 }}>
        <Typography>No assets found.</Typography>
      </Paper>
    );
  }
}

export default function ProjectDetail({ projectId }) {
  const { user, isLoading, getAccessTokenSilently } = useAuth0();

  const [enqueuedAssets, setEnqueuedAssets] = useState([]);
  const [project, setProject] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);

  const fetchProject = async () => {
    const accessToken = await getAccessTokenSilently();
    const url = `${process.env.REACT_APP_API_URL}/projects/${projectId}`;
    const headers = { 'content-type': 'application/json', Authorization: `Bearer ${accessToken} ` };

    await axios
      .get(url, { headers: headers })
      .then((response) => response.data)
      .then((data) => setProject(data));
  };

  const [openCreateDialog, setOpenCreateDialog] = React.useState(false);
  const [openUploadDialog, setOpenUploadDialog] = React.useState(false);

  useEffect(() => {
    fetchProject(projectId);
  }, [openUploadDialog, projectId]);

  const refreshAssets = () => {
    fetchProject(projectId);
  };

  const handleClickCreateDocument = () => {
    setOpenCreateDialog(true);
  };

  const handleCloseCreateDocument = () => {
    setOpenCreateDialog(false);
    setArtifactToCreateName('');
  };

  const handleClickUpload = () => {
    setOpenUploadDialog(true);
  };

  const handleCloseUpload = () => {
    setOpenUploadDialog(false);
    setTimeout(() => fetchProject(), 2000);
  };

  const [artifactToCreateType, setArtifactToCreateType] = useState('');
  const [artifactToCreateName, setArtifactToCreateName] = useState('');

  const [activeArtifactId, setActiveArtifactId] = useState(null);

  const createArtifact = async () => {
    const newArtifact = {
      name: artifactToCreateName,
      project_id: projectId,
      type: artifactToCreateType,
    };

    const accessToken = await getAccessTokenSilently();
    const url = `${process.env.REACT_APP_API_URL}/projects/${projectId}/artifacts`;
    const headers = { 'content-type': 'application/json', Authorization: `Bearer ${accessToken} ` };

    await axios
      .post(url, newArtifact, { headers: headers })
      .then((response) => response.data)
      .then((data) => {
        console.log('Created artifact:', data);
        handleCloseCreateDocument();
        fetchProject();
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          if (error.response.status == 409) {
            enqueueSnackbar('That name is already in use.  Choose a different name.', { variant: 'error' });
          } else {
            enqueueSnackbar('An error occurred when creating the artifact.', { variant: 'error' });
          }
        }
        console.log('Error creating artifact:', error);
      });
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleContentTypeListItemClick = (index) => {
    setSelectedArtifactTypeIndex(index);
    setArtifactToCreateType(project.artifact_types[index]);
  };

  const { activeProjectId, setActiveProjectId, activeContentType, setActiveContentType } = useContext(ViewContext);

  const handleBackClick = () => {
    setActiveProjectId(null);
  };

  const updateProjectSettings = async (projectPrompt, artifactTypes, colorPalette, font) => {
    console.log('Updating project settings:');
    console.log(projectPrompt);
    console.log(artifactTypes);
    console.log(colorPalette);
    console.log(font);

    const accessToken = await getAccessTokenSilently();
    const url = `${process.env.REACT_APP_API_URL}/projects/${projectId}/settings`;
    const headers = { 'content-type': 'application/json', Authorization: `Bearer ${accessToken} ` };

    const requestData = {
      project_prompt: projectPrompt,
      artifact_types: artifactTypes ? artifactTypes : [],
      color_palette: colorPalette ? colorPalette : {},
      font: font ? font : '',
    };

    await axios
      .put(url, requestData, { headers: headers })
      .then((response) => response.data)
      .then((data) => console.log('Project settings updated:', data))
      .catch((error) => {
        if (error.response.status === 403) {
          enqueueSnackbar('You do not have permission to update this project.', { variant: 'error' });
        } else {
          enqueueSnackbar('An error occurred when updating the project settings.', { variant: 'error' });
        }
      })
      .finally(() => fetchProject());
  };

  const [selectedArtifactTypeIndex, setSelectedArtifactTypeIndex] = useState(null);

  const isCreateDisabled = Boolean((artifactToCreateName === '') | (selectedArtifactTypeIndex == null));
  return (
    <>
      <Stack direction="column" spacing={2} sx={{ height: '100vh', paddingBottom: '30px' }}>
        {!activeArtifactId && (
          <>
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: 1,
                m: 1,
              }}
            >
              <IconButton onClick={handleBackClick}>
                <ChevronLeftIcon />
              </IconButton>
              <Typography
                variant="h5"
                sx={{ verticalAlign: 'middle', position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}
              >
                {(project && project.name) || ''}
              </Typography>
              <Toolbar>
                {tabIndex === 0 && (
                  <Button variant="contained" color="primary" onClick={handleClickCreateDocument}>
                    + Create Document
                  </Button>
                )}
                {tabIndex === 1 && (
                  <Button variant="contained" color="primary" onClick={handleClickUpload}>
                    + Upload an Asset
                  </Button>
                )}
              </Toolbar>
            </Box>

            <Tabs value={tabIndex} onChange={handleTabChange} aria-label="simple tabs example">
              <Tab label="Documents" value={0} />
              <Tab label="Assets" value={1} />
              {!isLoading && user && isAdmin(user) && <Tab label="Settings" value={2} />}
            </Tabs>

            {tabIndex === 0 && <ArtifactList project={project} onArtifactsChanged={fetchProject} />}
            {tabIndex === 1 && (
              <AssetListTable project={project} onAssetsChanged={fetchProject} enqueuedAssets={enqueuedAssets} />
            )}
            {tabIndex === 2 && <ProjectSettings project={project} updateProjectSettings={updateProjectSettings} />}
          </>
        )}
      </Stack>

      <AssetUploadDialog
        open={openUploadDialog}
        onClose={handleCloseUpload}
        projectId={projectId}
        refreshAssets={refreshAssets}
      ></AssetUploadDialog>

      <Dialog open={openCreateDialog} onClose={handleCloseCreateDocument} sx={{ flexGrow: '1' }}>
        <DialogTitle>New Document</DialogTitle>
        <DialogContent>
          <Paper style={{ maxHeight: '75%', overflow: 'auto' }}>
            <List>
              {project &&
                project.artifact_types &&
                project.artifact_types
                  .filter((type) => !type.is_hidden)
                  .map((type, index) => (
                    <ListItemButton
                      onClick={() => handleContentTypeListItemClick(index)}
                      selected={selectedArtifactTypeIndex === index}
                    >
                      <ListItemIcon>
                        <Article />
                      </ListItemIcon>
                      <ListItemText primary={type.name} secondary={type.description} />
                    </ListItemButton>
                  ))}
            </List>
          </Paper>
          <DialogContentText></DialogContentText>

          <TextField
            sx={{ marginTop: 2 }}
            value={artifactToCreateName}
            onChange={(event) => {
              setArtifactToCreateName(event.target.value);
            }}
            label="Document Name"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCreateDocument}>Cancel</Button>
          <Button onClick={createArtifact} disabled={isCreateDisabled}>
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}


export const defaultProjectPrompt = `
You are a helpful marketing assistant that creates content for technical decision makers.

Your content should be:
- Compelling and engaging
- Help the user understand the topic and its goals
- Address the user's concerns and challenges
- Be relevant and applicable to the user's industry
`;

export const defaultArtifactTypes = [
  {
    name: 'Blog Post',
    description: 'A blog post that shares an idea, problem, or solution.',
    prompts: {
      'content_type_prompt': 'content prompt for a blog post',
      'regen_all_prompt': 'Regenerate all prompts',
      'regen_chapter_prompt': 'Regenerate prompts for a specific chapter',
      'section_prompt': 'Select a section to regenerate prompts for'
    }
  },
  {
    name: 'EBook',
    description: 'An eBook that explores an idea, problem, or solution.',
    prompts: {
      'content_type_prompt': 'content prompt for a eBook',
      'regen_all_prompt': 'eBookReg enerate all prompts',
      'regen_chapter_prompt': 'eBook Regenerate prompts for a specific chapter',
      'section_prompt': 'eBook Select a section to regenerate prompts for'
    }
  }
];

export const defaultSystemPrompt = `
You are a helpful marketing assistant for GitLab. You create content that always follows the GitLab "Tone of voice" guidelines:
"Our voice embodies our values and mission. We openly welcome others into our collaborative space, sharing an inclusive perspective on GitLab’s vision.
Our brand principles extend to our voice and communications as well. Consider how each characteristic works together to create a holistic narrative. For instance, our human-centric approach balances our ambitious edge; our concise messaging reinforces our trustworthiness; and so on. All facets are needed to fully represent who we are. Depending on the audience, it may make sense to lean into one area more than another."

The audience you are writing for is tech executives.  Create content suitable for this role. Here is more information about the role:
"
Role: Tech Exec

Job To Be Done:
Lead the technology vision, strategy, innovation, and execution for the organization

Summary:
I’m really motivated by leading organizational change
I’m an Influencer and Initiator/Identifier of need for tech purchases but I am very often the decision-maker
My biggest challenges are siloed teams and workflows along with the need to increase the speed of development as well as scaling efficiencies and new ways of working across multiple projects and environments
Key messaging concepts to include in the buyer’s journey: budget, cost, ROI, cost analysis, value, etc.


My Messaging:
Create secure code faster with continuous improvment through accelerated feedback loops
Do less with more with built-in support automation and integrations with 3rd-party sevices
Enforce compliance, have visibility, and better prepare for audits
Have visibility across your entire SDLC and Supply Chain

Potential Titles:
CIO/ CTO / CINO / CDO / Founder / Owner / VP

Reports to:
CEO / Board

Job Responsibilities:
Develop goals and strategies to ensure the IT department runs smoothly and effectively
Direct and establish IT-related projects
Monitor technology sector changes to discover ways the company can improve and develop
Supervise company networks and computer systems to ensure optimal performance
Plan and direct implementation of new IT systems

Goals & Objectives:
Helping IT to play a more strategic role in achieving business objectives (tied to Digital Transformation)

Motivated by:
Contributing to solving real problems
Leading organizational change
Having autonomy / independence
Empowering teams without sacrificing security & quality

Biggest Challenges:
Siloed teams and workflows
The need to increase the speed of development
Scaling efficiencies and new ways of working across multiple projects and environments
Slow digital transformation initiatives
Current processes that do not scale
Hiring and training staff with relevant skill sets (skills gap)
Retention of people who do not want to work on uninteresting platforms
High cost of downtime
`;


export const defaultProductPrompt = `
3 Key Metrics to Measure Developer Productivity
AI implementation may only add marginal value to an organization’s software development process without clear productivity goals. 

Organizations are understandably excited about the potential for AI coding assistants to increase the productivity of software developers. In a recent survey by GitLab, 83% of respondents said it is essential to implement AI in their software development processes to avoid falling behind.

The promise that AI can automate most software development or at least make developers significantly more productive raises the question of how developer productivity is measured. Hint: It’s not about code volume.

While most measurements of developer productivity focus on task metrics such as lines of code produced or the number of code commits, developer productivity can’t be measured in a single dimension. Multiple dimensions of developer performance must be considered to accurately measure developer productivity.

Task: A Deceptive Measurement
The task dimension covers the most direct measure of individual developer output — efficiently completing an assigned task within a set timeframe. While seemingly straightforward, measuring task completion as an indication of productivity can be misguided — for two reasons.

First, software developers typically work in teams, with individuals working on different aspects of software development simultaneously. Therefore, measuring individual developers using the same task-oriented metrics provides an inaccurate representation of team productivity.


GitLab is the most comprehensive, AI-powered DevSecOps platform for software innovation. GitLab enables organizations to increase developer productivity, improve operational efficiency, reduce security and compliance risk, and accelerate digital transformation.
Learn More
THE LATEST FROM GITLAB
Developing GitLab Duo: Use AI to remediate security vulnerabilities
15 July 2024
Developing GitLab Duo: A roundup of recent Chat enhancements
10 July 2024
FAQ: The RegreSSHion vulnerability and GitLab
9 July 2024
Second, completing more tasks isn’t necessarily the smartest way to solve a problem. For example, less skilled programmers or AI coding assistants can produce large amounts of boilerplate code. For example, a recent vendor study showed that developers could finish coding a web server in JavaScript 90 minutes faster using AI than without it. It was an impressive result, but they could have reused an existing web-server abstraction to create the same outcome in minutes.

Problem-solving is a critical skill for any software developer because they regularly face complex challenges that require innovative solutions. Developers must be able to accurately identify problems, collaborate with team members to generate potential solutions and, ultimately, choose the best course of action to address the issue.

TRENDING STORIES
DevOps Isn't Dead, but It's Not in Great Health Either
What GitHub Pull Requests Reveal about Your Team's Dev Habits
DevOps Embraces Observability Across Stacks for LLM Era
DevOps Reveals: Trust Your Team for Better Results
Backend Development Efficiency: The Critical Role of Caching
Individual developer efficiency, which requires a combination of technical skills, critical thinking and creativity, is a more optimal metric than number of tasks completed. In one famous example, Bill Atkinson, the principal designer and developer of the graphical user interface of Apple’s Lisa computer, optimized Quickdraw’s region calculation routine to be six times faster and 2,000 lines of code shorter. His contribution defied Apple’s productivity metric of tracking individual engineers by the amount of code they wrote each week in favor of a simpler, faster and more efficient solution.

Integrating AI into the software development process to augment developer efficiencies, such as via test automation, code refactoring and documentation, can free up developers’ time to take on more complex work that will deliver much greater value for organizations and end users. Therefore, now more than ever, measuring output, like lines of code, is irrelevant and we should focus on measuring business outcomes.

Time: The Velocity Measurement
Software development teams know that iterative development practices outperform traditional waterfall models. High cadence leads to tight feedback loops where improvements made in one cycle, such as an abstraction to replace boilerplate code, lead to further improvements in later cycles.

Time metrics are an objective measurement. In Google’s framework for measuring developer productivity, the DevOps Research and Assessment (DORA) rubric, three of the four metrics are directly time-based:

Deployment frequency (hours/days/weeks/months)
Lead time for changes
Time to restore service
DORA metrics measure the team’s performance over a specific timeframe. They serve as key indicators of a team’s agility, operational efficiency and velocity and will reflect how well an organization balances speed and software stability.

More than six years of research by the DORA team has established that these metrics can be used to identify exactly where and how to improve, with short iteration cycles consistently leading to better outcomes for development organizations.

DORA metrics can also reveal where poor developer experiences may be hurting developer velocity. For example, a development team might be producing great code but showing terrible DORA metrics because of an error-prone testing and deployment process.

The solution isn’t asking a talented but overburdened development team to work harder; it’s a call for development team leaders to take a holistic view of the development pipeline and identify where process improvements can be implemented. For example, introducing AI and automation into the code review and testing process can improve DORA metrics and software delivery performance.

Team: The Human-Centered Measurement
The team dimension considers business outcomes in a wider organizational context. While software development teams must work efficiently together, they must also work with teams across other business units.

Often, non-technical factors, such as peer support, working environment, psychological safety and job enthusiasm play a significant role in boosting productivity.

Another framework is SPACE, which is an acronym for satisfaction, performance, activity, communication and efficiency. SPACE was developed to capture some of the more nuanced and human-centered dimensions of productivity. SPACE metrics, in combination with DORA metrics, can fill in the productivity measurement gaps by correlating productivity metrics to business outcomes.

McKinsey found that combining DORA and SPACE metrics with “opportunity-focused” metrics can produce a well-rounded view of developer productivity. That, in turn, can lead to positive outcomes, as McKinsey reports: 20% to 30% reduction in customer-reported product defects, 20% improvement in employee experience scores and 60% improvement in customer satisfaction ratings.

What Are You Trying to Optimize?
Just like the three dimensions of a cube all contribute to its volume, the task, time and team dimensions of developer productivity all contribute to total business impact.

While developers may focus on the most easily measured task dimensions of their productivity, such as lines of code written per day or merge requests accepted per week, those metrics must be considered in the larger business context, such as when more lines of code may negatively impact the team and time dimensions.

The importance of engineering talent to a company’s success and the fierce competition for developer talent underscores the need to acknowledge that software development requires measurement to improve.
`

export const defaultProductPromptOld = `
Product info: GitLab Analytics

Offering: GitLab.com, Self-managed, GitLab Dedicated
History 
The product analytics feature empowers you to track user behavior and gain insights into how your applications are used and how users interact with your product. By using the data collected with product analytics in GitLab, you can better understand your users, identify friction points in funnels, make data-driven product decisions, and ultimately build better products that drive user engagement and business growth.

 For an overview of the product analytics setup and functionality, watch the Product Analytics walkthrough videos.

For more information about the vision and development of product analytics, see the group direction page. To leave feedback about product analytics bugs or functionality:

Comment on issue 391970.
Create an issue with the group::product analytics label.
How product analytics works
Product analytics uses the following tools:

Snowplow - A developer-first engine for collecting behavioral data and passing it through to ClickHouse.
ClickHouse - A database suited to store, query, and retrieve analytical data.
Cube - A universal semantic layer that provides an API to run queries against the data stored in ClickHouse.
The following diagram illustrates the product analytics flow:

Data visualization
Data warehouse
Event collection
Send user data
Pass data
Transform and enrich data
Request data
Run Cube queries with pre-aggregations
Get data
Return results
Transform data to be rendered
Return data
Dashboards with panels/visualizations
Product Analytics API
Cube
ClickHouse
Snowplow Collector
SDK
Snowplow Enricher
Enable product analytics
History 
To track events in your project’s applications, you must enable and configure product analytics.

Product analytics provider
Your GitLab instance connects to a product analytics provider. A product analytics provider is the collection of services required to receive, process, store and query your analytics data.

GitLab-managed provider
Self-managed provider
Offering: GitLab.com
On GitLab.com, if you signed up for beta, you can use a GitLab-managed provider offered only in the Google Cloud Platform zone us-central-1. To sign up, contact the GitLab sales team.

If GitLab manages your product analytics provider, then your analytics data is retained for one year. You can request to delete your data at any time by contacting support.

Instance-level settings
Offering: Self-managed, GitLab Dedicated

Prerequisites:

You must have administrator access for the instance.
These instance-level settings are required to enable product analytics on GitLab self-managed and GitLab Dedicated, and cascade to all projects by default.
To enable product analytics on your instance:

On the left sidebar, at the bottom, select Admin Area.
Select Settings > Analytics.
Enter the configuration values.
Select Save changes.
Project-level settings
If you want to have a product analytics instance with a different configuration for your project, you can override the instance-level settings defined by the administrator on a per-project basis.

Prerequisites:

You must have at least the Maintainer role for the project or group the project belongs to.
The project must be in a group namespace.
On the left sidebar, select Search or go to and find your project.
Select Settings > Analytics.
Expand Data sources and enter the configuration values.
Select Save changes.
Onboard a GitLab project
History 
Prerequisites:

You must have at least the Maintainer role for the project or group the project belongs to.
Onboarding a GitLab project means preparing it to receive events that are used for product analytics.

To onboard a project:

On the left sidebar, select Search or go to and find your project.
Select Analyze > Analytics dashboards.
Under Product analytics, select Set up.
Then continue with the setup depending on the provider type.

GitLab-managed provider
Self-managed provider
Prerequisites:

You must have access to the GitLab-managed provider.
Select the I agree to event collection and processing in this region checkbox.
Select Connect GitLab-managed provider.
Remove already configured project-level settings for a self-managed provider:
Select Go to analytics settings.
Expand Data sources and remove the configuration values.
Select Save changes.
Select Analyze > Analytics dashboards.
Under Product analytics, select Set up.
Select Connect GitLab-managed provider.
Your instance is being created, and the project onboarded.

Instrument your application
You can instrument code to collect data by using tracking SDKs.

Product analytics dashboards
History 
Product analytics dashboards are a subset of dashboards under Analytics dashboards.

Specifically, product analytics dashboards and visualizations use the cube_analytics data type. The cube_analytics data type connects to the Cube instance defined when product analytics was enabled. All filters and queries are sent to the Cube instance, and the returned data is processed by the product analytics data source to be rendered by the appropriate visualizations.

Data table visualizations from cube_analytics have an additional configuration option for rendering links. This option is an array of objects, each with text and href properties to specify the dimensions to be used in links. If href contains multiple dimensions, values are joined into a single URL. View an example.

Filling missing data
History 
When exporting data or viewing dashboards, if there is no data for a given day, the missing data is autofilled with 0.

The autofill approach has both benefits and limitations.

Benefits:
The visualization’s day axis matches the selected date range, removing ambiguity about missing data.
Data exports have rows for the entire date range, making data analysis easier.
Limitations:
The day granularity must be used. All other granularities are not supported.
Only date ranges defined by the inDateRange filter are filled.
The date selector in the UI already uses this filter.
The filling of data ignores the query-defined limit. If you set a limit of 10 data points over 20 days, it returns 20 data points, with the missing data filled by 0. Issue 417231 proposes a solution to this limitation.
Funnel analysis
Use funnel analysis to understand the flow of users through your application, and where users drop out of a predefined flow (for example, a checkout process or ticket purchase).

Each project can define an unlimited number of funnels. Like dashboards, funnels are defined with the GitLab YAML schema and stored in the .gitlab/analytics/funnels/ directory of a project repository. If a repository has a custom dashboards pointer project that points to another repository, funnels must be defined in the pointer project.

Create a funnel dashboard
To create a funnel dashboard, you must first create a funnel definition file and a visualization. Each funnel must have a custom visualization defined for it. When funnel definitions and visualizations are ready, you can create a custom dashboard to visualize funnel analysis behavior.

Create a funnel definition
In the .gitlab/analytics/ directory, create a directory named funnels.
In the new .gitlab/analytics/funnels directory, create a funnel definition YAML file.
Funnel definitions must include the key seconds_to_convert and an array of steps.

Key	Description
seconds_to_convert	The number of seconds a user has to complete the funnel.
steps	An array of funnel steps.
Each step must include the keys name, target, and action.

Key	Description
name	The name of the step. This should be a unique slug.
action	The action performed. (Only pageview is supported.)
target	The target of the step. (Because only pageview is supported, this should be a path.)
The following example defines a funnel that tracks users who completed a purchase within one hour by going through three target pages:

seconds_to_convert: 3600
steps:
  - name: view_page_1
    target: '/page1.html'
    action: 'pageview'
  - name: view_page_2
    target: '/page2.html'
    action: 'pageview'
  - name: view_page_3
    target: '/page3.html'
    action: 'pageview'

Create a funnel visualization
To create funnel visualizations, follow the steps for defining a chart visualization. Funnel visualizations support the measure count and the dimension step.

The following example defines a column chart that visualizes the number of users who reached different steps in a funnel:

version: 1
type: ColumnChart
data:
  type: cube_analytics
  query:
    measures:
      - FUNNEL_NAME.count
    dimensions:
      - FUNNEL_NAME.step
    limit: 100
    timezone: UTC
    timeDimensions: []
options:
  xAxis:
    name: Step
    type: category
  yAxis:
    name: Total
    type: value

The funnel name defined in the YAML definition is converted to a slug that can be referenced in visualization definitions. For example, the funnel name Successful Conversions is converted to successful_conversions.
Query a funnel
You can query the funnel data with the REST API. To do this, you can use the example query body below, where you need to replace FUNNEL_NAME with your funnel’s name.

The name of a funnel is generated from the filename of the funnel definition YAML file, by separating words with underscores and removing special characters. For example, for a funnel definition file in .gitlab/analytics/funnels/Successful Conversions.yaml the funnel name is successful_conversions. This funnel name can be referenced in visualization definitions.
The afterDate filter is not supported. Use beforeDate or inDateRange.
{
  "query": {
      "measures": [
        "FUNNEL_NAME.count"
      ],
      "order": {
        "FUNNEL_NAME.count": "desc"
      },
      "filters": [
        {
          "member": "FUNNEL_NAME.date",
          "operator": "beforeDate",
          "values": [
            "2023-02-01"
          ]
        }
      ],
      "dimensions": [
        "FUNNEL_NAME.step"
      ]
    }
}

Raw data export
Exporting the raw event data from the underlying storage engine can help you debug and create datasets for data analysis.

Because Cube acts as an abstraction layer between the raw data and the API, the exported raw data has some caveats:

Data is grouped by the selected dimensions. Therefore, the exported data might be incomplete, unless including both utcTime and userAnonymousId.
Data is by default limited to 10,000 rows, but you can increase the limit to maximum 50,000 rows. If your dataset has more than 50,000 rows, you must paginate through the results by using the limit and offset parameters.
Data is always returned in JSON format. If you need it in a different format, you need to convert the JSON to the required format using a scripting language of your choice.
Issue 391683 tracks efforts to implement a more scalable export solution.

Export raw data with Cube queries
You can query the raw data with the REST API, and convert the JSON output to any required format.

To export the raw data for a specific dimension, pass a list of dimensions to the dimensions key. For example, the following query outputs the raw data for the attributes listed:

POST /api/v4/projects/PROJECT_ID/product_analytics/request/load?queryType=multi

{
    "query":{
  "dimensions": [
    "TrackedEvents.docEncoding",
    "TrackedEvents.docHost",
    "TrackedEvents.docPath",
    "TrackedEvents.docSearch",
    "TrackedEvents.eventType",
    "TrackedEvents.localTzOffset",
    "TrackedEvents.pageTitle",
    "TrackedEvents.src",
    "TrackedEvents.utcTime",
    "TrackedEvents.vpSize"
  ],
  "order": {
    "TrackedEvents.apiKey": "asc"
  }
    }
}

If the request is successful, the returned JSON includes an array of rows of results.

View product analytics usage quota
History 
Product analytics usage quota is calculated from the number of events received from instrumented applications.

To view product analytics usage quota:

On the left sidebar, select Search or go to and find your group.
Select Settings > Usage quota.
Select the Product analytics tab.
The tab displays the monthly totals for the group and a breakdown of usage per project. The current month displays events counted to date.

The usage quota excludes projects that are not onboarded with product analytics.

Best practices
Define key metrics and goals from the start. Decide what questions you want to answer so you know how to use collected data.
Use event data from all stages of the user journey. This data provides a comprehensive view of the user experience.
Build dashboards aligned with team needs. Different teams need different data insights.
Review dashboards regularly. This way, you can verify customer outcomes, identify trends in data, and update visualizations.
Export raw data periodically. Dashboards provide only an overview of a subset of data, so you should export the data for a deeper analysis.
Troubleshooting
`;


export const defaultContentPrompt = `
Create the table of contents for an e-book about the topic described below.  
For each chapter, list the chapter number, chapter title, and 3 to 5 main points.
Before the first chapter and between each subsequent chapter, output the string <%%%>
`;

export const defaultChapterPrompt = `Write the full chapter for this table of contents.
Use bullet points sparingly.  Always start with the chapter title and a regular paragraph.
The length should be around 500 words.  Here's the original TOC:

`


export const defaultAllChaptersPrompt = `Complete all chapters in the e-book. Use bullet points sparingly.  
Always start with the chapter title and a regular paragraph.  Here's the original content: `;

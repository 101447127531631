import * as React from 'react';
import Typography from '@mui/material/Typography';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
} from '@mui/material';
import { useState } from 'react';

import { CheckCircle, Delete, Save } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import userEvent from '@testing-library/user-event';
import { isAdmin } from '../auth/utils';
import { useAuth0 } from '@auth0/auth0-react';
import * as defaultPrompts from '../defaultPrompts';
import { GridExpandMoreIcon } from '@mui/x-data-grid';

export default function ProjectSettings({ project, updateProjectSettings }) {
  const { user, getAccessTokenSilently } = useAuth0();
  const [projectPrompt, setProjectPrompt] = useState(project.project_prompt);
  const [artifactTypes, setArtifactTypes] = useState([...project.artifact_types]);

  const [edited, setEdited] = useState(false);

  const createNewArtifactType = (name) => {
    console.log('Creating new artifact type: ', name);
    const newArtifactType = {
      name: name,
      description: '',
      prompts: defaultPrompts.defaultNewArtifactTypePrompts,
    };
    const newArtifactTypes = [newArtifactType, ...artifactTypes];
    setArtifactTypes(newArtifactTypes);
    updateProjectSettings(projectPrompt, artifactTypes, colorPalette, font);
  };

  const updateArtifactTypeIsMultishot = (artifactTypeIndex, isMultishot) => {
    var newArtifactTypes = [...artifactTypes];
    newArtifactTypes[artifactTypeIndex].is_multishot = isMultishot;
    setArtifactTypes(newArtifactTypes);
    console.log(
      `updated is_multishot for ${newArtifactTypes[artifactTypeIndex].name}: ${newArtifactTypes[artifactTypeIndex].is_multishot}`
    );
  };

  const updateArtifactTypePrompt = (artifactTypeIndex, promptKey, newPrompt) => {
    var newArtifactTypes = [...artifactTypes];
    newArtifactTypes[artifactTypeIndex].prompts[promptKey] = newPrompt;
    setArtifactTypes(newArtifactTypes);
    console.log(
      `updated prompt for ${newArtifactTypes[artifactTypeIndex].name}: ${newArtifactTypes[artifactTypeIndex].prompts[promptKey].prompt}`
    );
  };

  const updateArtifactTypeDescription = (index, desc) => {
    console.log('Updating index: ', index, ' desc: ', desc);
    var newArtifactTypes = [...artifactTypes];
    newArtifactTypes[index].description = desc;
    setArtifactTypes(newArtifactTypes);
  };

  const [newArtifactTypeOpen, setNewArtifactTypeOpen] = useState(false);
  const [newArtifactTypeName, setNewArtifactTypeName] = useState('');
  const handleNewArtifactTypeOpen = () => {
    setNewArtifactTypeOpen(true);
  };

  const handleHideContentType = async (index) => {
    if (window.confirm('Are you sure you want to hide this content type?')) {
      artifactTypes[index].is_hidden = true;
      updateProjectSettings(projectPrompt, artifactTypes, colorPalette, font);
    }
  };

  const [colorPalette, setColorPalette] = useState(project.color_palette);

  const handleColorChange = (e, color) => {
    setEdited(true);
    const newColorPalette = { ...colorPalette };
    newColorPalette[color] = e.target.value;
    setColorPalette(newColorPalette);
  };

  const [font, setFont] = useState(project.font);

  const handleFontChange = (event) => {
    setFont(event.target.value);
    setEdited(true);
  };

  return (
    <>
      <Dialog open={newArtifactTypeOpen}>
        <DialogContent>
          <Stack direction="row" spacing={2}>
            <Typography variant="subtitle1">New Content Type</Typography>
          </Stack>
          <TextField
            label="Name"
            fullWidth
            value={newArtifactTypeName}
            onChange={(e) => setNewArtifactTypeName(e.target.value)}
          />
          <DialogActions>
            <Button onClick={() => setNewArtifactTypeOpen(false)}>Cancel</Button>
            <Button
              onClick={() => {
                createNewArtifactType(newArtifactTypeName);
                setNewArtifactTypeOpen(false);
              }}
            >
              Save
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Box sx={{ overflowY: 'scroll', height: 'auto' }}>
        <Stack direction="column" spacing={2}>
          <Typography variant="h6">Project Information</Typography>
          <TextField
            label="Project Prompt"
            fullWidth
            multiline
            sx={{ backgroundColor: 'white', marginBottom: '10px' }}
            minRows={6}
            maxRows={12}
            value={projectPrompt}
            onChange={(e) => {
              setEdited(true);
              setProjectPrompt(e.target.value);
            }}
          />
          <Stack direction="column">
            <Typography variant="h6">Content Types</Typography>
            <Button onClick={handleNewArtifactTypeOpen}>Add New Type</Button>
          </Stack>
          {artifactTypes &&
            artifactTypes
              .filter((item) => !item.is_hidden)
              .map((artifact_type, artifactTypeIndex) => {
                return (
                  <Card>
                    <CardContent>
                      <Stack key={artifact_type._id} direction="column" spacing={2}>
                        <Stack direction="row">
                          <Typography variant="subtitle1" fontWeight={'bold'} textAlign={'left'} marginBottom={'5px'}>
                            {artifact_type.name}
                          </Typography>
                          {isAdmin(user) && (
                            <IconButton onClick={() => handleHideContentType(artifactTypeIndex)}>
                              <Delete />
                            </IconButton>
                          )}
                        </Stack>
                        <TextField
                          label="Description (for user understanding. does not affect the AI)"
                          fullWidth
                          value={artifact_type.description}
                          onChange={(e) => {
                            setEdited(true);
                            updateArtifactTypeDescription(artifactTypeIndex, e.target.value);
                            setArtifactTypes([...artifactTypes]);
                          }}
                        />

                        <Accordion sx={{ marginBottom: '10px' }}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            <Typography sx={{ fontWeight: 'medium' }}>Settings for {artifact_type.name}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Switch
                                    sx={{ marginBottom: '10px' }}
                                    checked={artifact_type.is_multishot}
                                    onChange={(event) => {
                                      setEdited(true);
                                      updateArtifactTypeIsMultishot(artifactTypeIndex, event.target.checked);
                                    }}
                                  />
                                }
                                label="Generate with multiple passes"
                              />
                            </FormGroup>

                            {artifact_type.prompts &&
                              Object.keys(artifact_type.prompts).map((key) => {
                                return (
                                  <TextField
                                    label={key}
                                    fullWidth
                                    multiline
                                    minRows={6}
                                    maxRows={12}
                                    value={artifactTypes[artifactTypeIndex].prompts[key] || ''}
                                    sx={{ marginBottom: '10px' }}
                                    onChange={(e) => {
                                      setEdited(true);
                                      console.log(e.target.value);
                                      updateArtifactTypePrompt(artifactTypeIndex, key, e.target.value);
                                    }}
                                  />
                                );
                              })}
                          </AccordionDetails>
                        </Accordion>
                      </Stack>
                    </CardContent>
                  </Card>
                );
              })}
          <Typography variant="h6">Fonts</Typography>
          <Card>
            <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <FormControl variant="outlined">
                <InputLabel id="primary-font-label">Primary Font</InputLabel>
                <Select
                  labelId="primary-font-label"
                  label="Primary Font"
                  onChange={handleFontChange}
                  value={font}
                  sx={{ textAlign: 'left' }}
                >
                  {project.fonts?.map((fontOption) => (
                    <MenuItem value={fontOption}>{fontOption}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </CardContent>
          </Card>
          <Typography variant="h6">Color Palette</Typography>
          <Card>
            <CardContent>
              <Stack direction="column" spacing={2}>
                <Stack direction="row" spacing={2}>
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    sx={{
                      width: '56px',
                      height: '56px',
                      bgcolor: colorPalette?.primary,
                      border: '1px solid #c4c4c4',
                      borderRadius: '4px',
                    }}
                  >
                    {project.color_palette ? null : (
                      <Typography color={'gray'} variant="body2">
                        Unset
                      </Typography>
                    )}
                  </Box>
                  <TextField
                    label="Primary Color"
                    fullWidth
                    value={colorPalette?.primary}
                    onChange={(e) => handleColorChange(e, 'primary')}
                  />
                </Stack>
                <Stack direction="row" spacing={2}>
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    sx={{
                      width: '56px',
                      height: '56px',
                      bgcolor: colorPalette?.secondary,
                      border: '1px solid #c4c4c4',
                      borderRadius: '4px',
                    }}
                  >
                    {project.color_palette ? null : (
                      <Typography color={'gray'} variant="body2">
                        Unset
                      </Typography>
                    )}
                  </Box>
                  <TextField
                    label="Secondary Color"
                    fullWidth
                    value={colorPalette?.secondary}
                    onChange={(e) => handleColorChange(e, 'secondary')}
                  />
                </Stack>
                <Stack direction="row" spacing={2}>
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    sx={{
                      width: '56px',
                      height: '56px',
                      bgcolor: colorPalette?.background,
                      border: '1px solid #c4c4c4',
                      borderRadius: '4px',
                    }}
                  >
                    {project.color_palette ? null : (
                      <Typography color={'GrayText'} variant="body2">
                        Unset
                      </Typography>
                    )}
                  </Box>
                  <TextField
                    label="Background Color"
                    fullWidth
                    value={colorPalette?.background}
                    onChange={(e) => handleColorChange(e, 'background')}
                  />
                </Stack>
              </Stack>
            </CardContent>
          </Card>

          <Box sx={{ height: '100px' }} />

          <div style={{ bottom: '40px', right: '40px', position: 'absolute' }}>
            <Fab
              variant="extended"
              color="primary"
              disabled={!edited}
              onClick={() => {
                updateProjectSettings(projectPrompt, artifactTypes, colorPalette, font);
                setEdited(false);
              }}
            >
              <CheckCircle sx={{ mr: 1 }} />
              Save Changes
            </Fab>
          </div>
        </Stack>
      </Box>
    </>
  );
}

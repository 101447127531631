import { UploadFile } from '@mui/icons-material';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { format, formatDistanceToNow, formatRelative, set, subDays } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';

const columns = [
  {
    field: 'filename',
    headerName: 'Name',
    flex: 1,
    editable: false,
  },
];

export default function ArtifactAssetTable({ assets, onSelectionChange, selectedAssetIds }) {
  console.log('Selected Asset IDs:', selectedAssetIds);

  const [selctionModel, setSelectionModel] = useState([]);

  useEffect(() => {
    setSelectionModel(selectedAssetIds);
  }, [selectedAssetIds]);

  return (
    <>
      <Typography variant="body2" component="div" sx={{ flexGrow: 1 }}>
        Select assets to be used in document creation
      </Typography>
      <DataGrid
        rows={assets}
        columns={columns}
        getRowId={(row) => row._id}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        checkboxSelection
        onRowSelectionModelChange={(newSelection) => {
          setSelectionModel(newSelection);
          onSelectionChange(newSelection);
          console.log('New selection:', newSelection);
        }}
        rowSelectionModel={selctionModel}
      />
    </>
  );
}

import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Menu,
  MenuItem,
  TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import * as projectDefaults from '../projectDefaults';
import { MoreVert } from '@mui/icons-material';
import axios from 'axios';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { useAuth0 } from '@auth0/auth0-react';
import { blue, grey, lightBlue, teal } from '@mui/material/colors';

export default function ProjectList({ onSelectProject }) {
  const { isLoading, getAccessTokenSilently } = useAuth0();

  const [projects, setProjects] = useState([]);
  const [highlightedProjects, setHighlightedProjects] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const fetchProjects = async () => {
    const accessToken = await getAccessTokenSilently();
    const url = `${process.env.REACT_APP_API_URL}/projects/`;
    const headers = { 'content-type': 'application/json', Authorization: `Bearer ${accessToken} ` };

    await axios
      .get(url, { headers: headers })
      .then((response) => response.data)
      .then((data) => setProjects(data))
      .catch((error) => {
        enqueueSnackbar('Error fetching projects', { variant: 'error' });
        console.error('Error fetching projects:', error);
      });
  };

  useEffect(() => {
    fetchProjects();
  }, [getAccessTokenSilently]);

  const [newProjectOpen, setNewProjectOpen] = useState(false);

  const handleClickNewProject = () => {
    setNewProjectOpen(true);
  };

  const handleNewProjectClose = () => {
    setNewProjectOpen(false);
  };

  const handleHighlightProject = (projectId) => {
    setHighlightedProjects([...highlightedProjects, projectId]);
  };

  const clearHighlightProject = () => {
    setHighlightedProjects([]);
  };

  const [projectToCreateName, setProjectToCreateName] = useState('');

  const createProject = async () => {
    const newProject = {
      name: projectToCreateName,
    };

    const accessToken = await getAccessTokenSilently();
    const url = `${process.env.REACT_APP_API_URL}/projects/`;
    const headers = { 'content-type': 'application/json', Authorization: `Bearer ${accessToken} ` };

    await axios
      .post(url, newProject, { headers: headers })
      .then((response) => response.data)
      .then((data) => {
        console.log('Created project:', data);
        handleNewProjectClose();
      })
      .then(() => {
        fetchProjects();
      })
      .catch((error) => {
        console.log(error.response.data);
        console.log(error.response.status);
        if (error.response.status === 409) {
          enqueueSnackbar('That name is already in use.  Choose a different name.', { variant: 'error' });
        } else {
          enqueueSnackbar('An error occurred when creating the project.', { variant: 'error' });
        }
      });
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const projMenuOpen = Boolean(anchorEl);
  const [activeProjectIdForProjectMenu, setActiveProjectIdForProjectMenu] = useState(null);

  const handleProjectMenuClick = (event, projectId) => {
    event.stopPropagation();
    setActiveProjectIdForProjectMenu(projectId);
    console.log('Context menu for ', projectId);

    setAnchorEl(event.currentTarget);
  };
  const handleProjectMenuClose = () => {
    setAnchorEl(null);
  };

  const handleRenameProject = async () => {
    const newName = window.prompt('Enter new project name:');
    if (newName) {
      const accessToken = await getAccessTokenSilently();
      const url = `${process.env.REACT_APP_API_URL}/projects/${activeProjectIdForProjectMenu}`;
      const headers = { 'content-type': 'application/json', Authorization: `Bearer ${accessToken} ` };
      const body = { name: newName };

      await axios
        .put(url, body, { headers: headers })
        .then(() => {
          fetchProjects();
        })
        .catch((error) => {
          if (error.response.status === 409) {
            enqueueSnackbar('That name is already in use.  Choose a different name.', { variant: 'error' });
          } else {
            enqueueSnackbar('An error occurred when renaming the project.', { variant: 'error' });
          }
        })
        .finally(() => {
          handleProjectMenuClose();
        });
    }
  };

  const handleDeleteProject = async () => {
    try {
      if (window.confirm('Are you sure you want to delete this project?')) {
        console.log(`OK! Deleting project with ID: ${activeProjectIdForProjectMenu}`);
        const accessToken = await getAccessTokenSilently();
        const url = `${process.env.REACT_APP_API_URL}/projects/${activeProjectIdForProjectMenu}`;
        const headers = { 'content-type': 'application/json', Authorization: `Bearer ${accessToken} ` };

        await axios
          .delete(url, { headers: headers })
          .then(() => {
            console.log('Project deleted');
            fetchProjects();
          })
          .catch((error) => {
            if (error.response.status === 403) {
              enqueueSnackbar('You do not have permission to delete this project.', { variant: 'warning' });
            } else {
              enqueueSnackbar('Error deleting project', { variant: 'error' });
              console.error('Error deleting project:', error);
            }
          });
      }
    } finally {
      handleProjectMenuClose();
    }
  };

  return (
    <>
      <Box
        component="main"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          height: '40px',
          alignItems: 'flex-start',
          width: '100%',
        }}
      >
        <Box
          component="main"
          sx={{
            display: 'flex',
            minWidth: '100%',
            flexDirection: 'row',
            height: '40px',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            mb: '100px',
          }}
        >
          <Typography variant="h5" gutterBottom>
            Projects
          </Typography>
          <Button variant="contained" color="primary" onClick={handleClickNewProject}>
            + New Project
          </Button>
        </Box>

        <Grid container spacing={3}>
          {projects.map((project) => (
            <Grid item lg={3}>
              <Card
                sx={{
                  bgcolor: highlightedProjects.includes(project._id) ? teal[50] : 'white',
                  minWidth: 190,
                  maxWidth: 200,
                  ':hover': {
                    bgcolor: highlightedProjects.includes(project._id) ?? grey[200],
                  },
                }}
              >
                <CardActionArea disableTouchRipple onClick={() => onSelectProject(project._id)}>
                  <CardContent sx={{ flexDirection: 'horizontal', display: 'flex' }}>
                    <Typography
                      sx={{
                        textAlign: 'left',
                        width: '80%',
                        marginRight: 'auto',
                      }}
                    >
                      {project.name || 'Project'}
                    </Typography>
                    <IconButton
                      disableTouchRipple
                      sx={{ padding: '0px' }}
                      onClick={(e) => handleProjectMenuClick(e, project._id)}
                    >
                      <MoreVert />
                    </IconButton>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Dialog open={newProjectOpen} onClose={handleNewProjectClose}>
        <DialogTitle>New Project</DialogTitle>
        <DialogContent>
          <DialogContentText>Creating a new project</DialogContentText>

          <TextField
            value={projectToCreateName}
            onChange={(event) => {
              setProjectToCreateName(event.target.value);
            }}
            label="Project Name"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setProjectToCreateName('');
              handleNewProjectClose();
            }}
          >
            Cancel
          </Button>
          <Button onClick={createProject}>Create</Button>
        </DialogActions>
      </Dialog>

      <Menu id="basic-menu" anchorEl={anchorEl} open={projMenuOpen} onClose={handleProjectMenuClose}>
        <MenuItem onClick={handleRenameProject}>Rename</MenuItem>
        <MenuItem onClick={handleDeleteProject}>Delete</MenuItem>
      </Menu>
    </>
  );
}

import { Avatar, Box, Grid, ListItem, Select, Stack, Typography } from '@mui/material';
import { useAuth0 } from "@auth0/auth0-react";
import LogoutButton from '../logout';

export const UserTile = () => {
    const { user } = useAuth0();

    if (!user) {
        return null;
    }

    return (
        <Grid container
            sx={{ paddingLeft:'10px'}}>
                <Grid item xs={2} alignContent={'center'}>
            <Box>
                <img
                    alt="profile-user"
                    width="50px"
                    height="50px"
                    src={user.picture}
                    style={{ cursor: "pointer", borderRadius: "50%" }}
                />
            </Box>
            </Grid>
            <Grid item xs={10} alignContent={'center'}>
            <Box>
                <Typography
                    fontWeight="bold"
                >
                    {user.name}
                </Typography>
                <LogoutButton/>
            </Box>
            </Grid>
        </Grid>
    );
};

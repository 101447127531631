import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { mainListItems, secondaryListItems } from './listItems';
import { Avatar, ListItem, Select, Stack } from '@mui/material';
import { useAuth0 } from "@auth0/auth0-react";
import { isAdmin } from './auth/utils';

export const UserProfile = () => {
  const { user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const [accessToken, setAccessToken] = React.useState(null);
  const [idTokenClaims, setIdTokenClaims] = React.useState(null);

  React.useEffect(() => {
    getAccessTokenSilently().then((newToken) => setAccessToken(newToken));
    console.log(user)
  }, [getAccessTokenSilently]);

  return (
    <Paper align="center">
      <Avatar
        src={user.picture}
        alt="Profile"
      />
      <Stack direction='column' spacing={2} sx={{width: '90%', wordWrap: 'break-word'}}>
        <h2 className="profile__title">{user.name}</h2>
        <Typography className="profile__description">{user.email}</Typography>
        <Typography>User: {JSON.stringify(user)}</Typography>
        <Typography>Access Token: {accessToken}</Typography>
        <Typography>Admin: {isAdmin(user) ? "YES": "NO"}</Typography>
      </Stack>

    </Paper>
  );
};


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}

      {new Date().getFullYear()}{' '}
      <Link target="_blank" color="inherit" href="https://acercaconsulting.com/">
        Acerca
      </Link>
      {'.'}
    </Typography>
  );
}

export default function Settings() {

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
      }}
    >
      <Toolbar />
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={5}>
          {/* Chart */}
          <Grid item xs={12}>
            <Paper>
              <Typography>Settings</Typography>
              <Typography>API Endpoint: {process.env.REACT_APP_API_URL}</Typography>
              <Typography>REACT_APP_AUTHZERO_AUDIENCE: {process.env.REACT_APP_AUTHZERO_AUDIENCE}</Typography>
              <Typography>REACT_APP_AUTHZERO_DOMAIN: {process.env.REACT_APP_AUTHZERO_DOMAIN}</Typography>
              <Typography>REACT_APP_AUTHZERO_CLIENT_ID: {process.env.REACT_APP_AUTHZERO_CLIENT_ID}</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper><UserProfile /></Paper>
          </Grid>



        </Grid>
        <Copyright sx={{ pt: 4 }} />
      </Container>
    </Box>
  );
}

import * as React from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  Typography,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  ListItemIcon,
} from '@mui/material';
import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { enqueueSnackbar } from 'notistack';
import { grey } from '@mui/material/colors';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageIcon from '@mui/icons-material/Image';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { set } from 'date-fns';

export default function AssetUploadDialog({
  open,
  onClose,
  projectId,
  refreshAssets,
  setEnqueuedAssets,
  enqueuedAssets,
}) {
  const { getAccessTokenSilently } = useAuth0();
  const [files, setFiles] = useState([]);
  const [hover, setHover] = useState(false);

  const handleFileChange = (e) => {
    const filesList = [...e.target.files];
    sanatizeAndSetFiles(filesList);
  };

  const sanatizeAndSetFiles = (filesList) => {
    for (const file of filesList) {
      if (
        file.type === 'application/pdf' ||
        file.type === 'image/png' ||
        file.type === 'image/jpg' ||
        file.type === 'image/jpeg' ||
        file.type === 'text/plain'
      ) {
        continue;
      } else {
        enqueueSnackbar(`Unsupported file type: ${file.type}`, { variant: 'error' });
        return;
      }
    }
    setFiles((files) => [...files, ...filesList]);
  };

  const onResetClose = () => {
    onClose();
    setFiles([]);
    setHover(false);
  };

  const handleUpload = async () => {
    console.log('Uploading files:', files);
    onResetClose();
    if (!files) {
      return;
    }
    for (const file of files) {
      const url = `${process.env.REACT_APP_API_URL}/projects/${projectId}/asset_upload_signed_url`;
      const accessToken = await getAccessTokenSilently();
      const headers = { 'content-type': 'application/json', Authorization: `Bearer ${accessToken}` };

      // get signed url
      const response = await axios.get(url, { headers: headers });

      // setEnqueuedAssets(files);

      await uploadFile(file, response.data.signed_url, response.data.file_path, headers);
    }
  };

  const uploadFile = async (file, signed_upload_url, cloudstorage_path, header) => {
    const blob = new Blob([file], { type: 'application/octet-stream' });

    try {
      console.log('Signed URL:', signed_upload_url);
      await axios
        .put(signed_upload_url, blob, {
          headers: {
            'Content-Type': 'application/octet-stream',
          },
        })
        .then((response) => {
          console.log('File upload response:', response);
          if (response.status === 200) {
            const requestData = {
              filename: file.name,
              path: cloudstorage_path,
              content_type: file.type,
            };
            console.log('Request data:', requestData);
            const updateAssetsResponse = axios
              .post(`${process.env.REACT_APP_API_URL}/projects/${projectId}/assets`, requestData, { headers: header })
              .then(() => {
                console.log('Asset uploaded:', file.name);
                // setEnqueuedAssets(enqueuedAssets.filter((item) => item.name !== file.name));
                refreshAssets();
              });
            console.log('Updated assets:', updateAssetsResponse.data);
          } else {
            console.error('Error uploading file:', response.data);
          }
        });
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleDragEnter = (e) => {
    setHover(true);
    e.preventDefault();
  };

  const handleDragLeave = (e) => {
    setHover(false);
    e.preventDefault();
  };

  const handleFileDrop = (e) => {
    e.preventDefault();
    setHover(false);
    const filesList = [...e.dataTransfer.files];
    sanatizeAndSetFiles(filesList);
  };

  return (
    <Dialog open={open} onClose={onResetClose} sx={{ flexGrow: '1' }} fullWidth="med">
      <DialogTitle>Upload an Asset</DialogTitle>
      <DialogContent>
        <Button
          variant="text"
          component="label"
          disableRipple
          onDragOver={(e) => {
            e.preventDefault();
            setHover(true);
          }}
          onDragLeave={handleDragLeave}
          onDragEnter={handleDragEnter}
          onDrop={handleFileDrop}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            outline: '2px dashed',
            outlineColor: grey[300],
            borderRadius: '2rem',
            marginTop: '10px',
            marginBottom: '20px',
            height: '300px',
            bgcolor: hover ? grey[200] : 'transparent',
          }}
        >
          <input
            type="file"
            accept=".pdf, .png, .jpg, .jpeg, .txt"
            onChange={handleFileChange}
            id="fileInput"
            hidden
            multiple
          />
          <CloudUploadIcon sx={{ fontSize: 75, color: grey[500] }} />
          <Typography color={grey[500]}>Click or Drag to upload a file</Typography>
        </Button>
        <DialogContentText>
          <Typography variant="subtitle2">Supported file types: pdf, png, jpg, txt</Typography>
        </DialogContentText>
        <List>
          {files.map((file) => {
            return (
              <ListItem>
                <ListItemIcon>
                  {file.type === 'application/pdf' ? (
                    <PictureAsPdfIcon />
                  ) : file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg' ? (
                    <ImageIcon />
                  ) : file.type === 'text/plain' ? (
                    <TextSnippetIcon />
                  ) : (
                    <CloudUploadIcon />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography noWrap overflow={'hidden'}>
                      {file.name}
                    </Typography>
                  }
                />
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleUpload}>Upload</Button>
        <Button onClick={onResetClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}

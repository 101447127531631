import * as React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';


export default function RegenerateDialog({ type /*chapter or document*/, open, setRegenDialogOpen, regenerateInstructions, setRegenerateInstructions, action }) {

    return (
        <Dialog open={open}>
            <DialogTitle>{ type === 'chapter' ? 'Edit Chapter' : 'Edit Document'}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    { type === 'chapter' ?
                        'Describe how you\'d like to change this chapter:' :
                        'Describe how you\'d like to change the document:' }
                </DialogContentText>
                <TextField content={regenerateInstructions}
                    onChange={(event) => setRegenerateInstructions(event.target.value)}
                    multiline rows={4} fullWidth />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setRegenDialogOpen(false)}>Cancel</Button>
                <Button onClick={() => {
                    setRegenDialogOpen(false);
                    action();
                }}>
                    Regenerate
                </Button>
            </DialogActions>
        </Dialog>
    );
}